<template>
  <div>
    <b-row class="mb-1 px-0 mx-0" style="overflow-x: auto; white-space: nowrap">
      <b-button
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'general' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'general'"
      >
        {{ lang("t_general") }}
      </b-button>
      <b-button
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'voice' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'voice'"
      >
        {{ lang("t_mVoice") }}
      </b-button>
      <b-button
        class="ml-50"
        style="width: 130px"
        :variant="
          selectedButton != 'backup' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'backup'"
      >
        {{ lang("t_backup") }}
      </b-button>
      <b-button
        class="ml-50"
        style="width: 130px"
        :variant="
          selectedButton != 'provider' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'provider'"
      >
        {{ lang("t_providers") }}
      </b-button>
      <!-- <b-button
        class="ml-50"
                style="width: 130px"

          :variant="
            selectedButton != 'tenant'
              ? 'outline-primary'
              : 'gradient-primary'
          "
          pill
          @click="selectedButton = 'tenant'"
        >
          Tenant
        </b-button> -->
    </b-row>
    <general v-if="selectedButton == 'general'"></general>
    <voice v-if="selectedButton == 'voice'"></voice>
    <backup v-if="selectedButton == 'backup'"></backup>
    <tenant-management v-if="selectedButton == 'tenant'"></tenant-management>
    <providers v-if="selectedButton == 'provider'"></providers>
  </div>
</template>

<script>
import { BContainer, BFormRadioGroup, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BLink, BButton } from 'bootstrap-vue'
import General from './General/General.vue'
import Voice from './Voice/VoiceMain.vue'
import Backup from './Backup/Backup.vue'
import Providers from './Providers/Main.vue'
// import ServiceManagement from './ServiceManagement/ServiceManagement.vue'
import TenantManagement from './TenantManagement/TenantManagement.vue'
// import Projects from './Projects/list/List.vue'

export default {
  data() {

    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      selectedButton: 'general',
    }
  },
  components: {
    BFormInput,
    BContainer,
    Voice,
    General,
    Backup,
    Providers,
    // ServiceManagement,
    TenantManagement,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton
  },
}
</script>

<style>
</style>
