<template>
  <div>
    <b-card-actions :title="lang('t_filters')" action-collapse>
      <b-row>
        <b-col cols="12">
          <div class="mx-auto" max-width="100%">
            <b-row>
              <b-col class="pb-0" cols="12">
                <b-row>
                  <!-- Başlangıç Tarihi -->
                  <b-col cols="6" class="pb-0">
                    <b-form-group>
                      <flat-pickr
                        :placeholder="lang('t_startDate')"
                        v-model="filter_values.startDate"
                        class="form-control"
                        :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i',
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Bitiş Tarihi -->
                  <b-col cols="6" class="pb-0">
                    <b-form-group>
                      <flat-pickr
                        :placeholder="lang('t_endDate')"
                        v-model="filter_values.endDate"
                        class="form-control"
                        :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i',
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Temsilciler -->
                  <b-col cols="12" class="mb-1">
                    <v-select
                      v-model="filter_values.selectedAgents"
                      multiple
                      :placeholder="lang('t_agent')"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="username"
                      :reduce="(item) => item.username"
                      :options="agents"
                    />
                  </b-col>
                  <!-- Gelen Butonu -->

                  <!-- Kuyruk -->
                  <b-col cols="12" class="mb-1">
                    <v-select
                      v-model="filter_values.selectedQueues"
                      multiple
                      :placeholder="lang('t_queue')"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="display_name"
                      :reduce="(item) => item.internal_name"
                      :options="queues"
                    />
                  </b-col>

                  <!-- Proje -->
                  <b-col cols="12" class="mb-1">
                    <v-select
                      v-model="filter_values.selectedProjects"
                      multiple
                      :placeholder="lang('t_project')"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="display_name"
                      :reduce="(item) => item.internal_name"
                      :options="projects"
                    />
                  </b-col>

                  <b-col cols="12" class="mb-1">
                    <b-row class="mx-25">
                      <b-form-checkbox v-model="filter_values.is_inbound">
                        {{ lang("t_inbound") }}
                      </b-form-checkbox>

                      <b-form-checkbox
                        class="ml-3"
                        v-model="filter_values.is_outbound"
                      >
                        {{ lang("t_outbound") }}
                      </b-form-checkbox>
                      <b-form-checkbox
                        class="ml-3"
                        v-model="filter_values.is_dialer"
                      >
                        {{ lang("t_dialer") }}
                      </b-form-checkbox>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card>
      <!-- Klasör formatı -->
      <b-col md="12" class="mb-1">
        <draggable
          :list="folder_format_columns"
          :group="{ name: 'g2', pull: 'clone', put: false }"
          :clone="cloneItem2"
        >
          <b-badge
            class="mr-50 badge-glow"
            v-for="(item, i) in folder_format_columns"
            :key="i"
            variant="primary"
          >
            {{ item.display_name }}
          </b-badge>
        </draggable>
      </b-col>
      <b-col md="12" class="pb-0">
        <draggable
          class="dragArea list-group card"
          tag="ul"
          :list="list3"
          :group="{ name: 'g2', type: 'folder_format' }"
          @add="changeItemId2"
        >
          <b-form-input
            v-model="currentData.folder_format"
            id="basicInput"
            :placeholder="lang('t_folderType')"
          />
        </draggable>
      </b-col>
      <!-- İsim formatı -->
      <b-col md="12" class="mb-1">
        <draggable
          :list="columns"
          :group="{ name: 'g1', pull: 'clone', put: false }"
          :clone="cloneItem"
        >
          <b-badge
            class="mr-50 badge-glow"
            v-for="(item, i) in columns"
            :key="i"
            variant="primary"
          >
            {{ item.display_name }}
          </b-badge>
        </draggable>
      </b-col>
      <b-col md="12" class="pb-0">
        <draggable
          class="dragArea list-group card"
          tag="ul"
          :list="list2"
          :group="{ name: 'g1', type: 'voice_export' }"
          @add="changeItemId"
        >
          <b-form-input
            v-model="currentData.name_format"
            id="basicInput"
            :placeholder="lang('t_nameType')"
          />
        </draggable>
      </b-col>

      <!-- Hedef Email -->
      <b-col md="12" class="pb-0">
        <b-form-group
          :label="lang('t_email')"
          label-for="helperInput"
          :description="lang('t_whenTheFilesAreReadyYouWillbeSentAnEMail')"
        >
          <b-form-input
            v-model="currentData.emails"
            id="helperInput"
            :placeholder="
              lang('t_youCanAddCommaBetweenEmailAddressesToAddMore')
            "
            trim
          />
        </b-form-group>
      </b-col>
      <!-- Dosya Formatı Radio Button -->
      <b-col md="12" class="pb-0 ml-1">
        <b-row>
          <span class="mr-3">{{ lang("t_fileType") }}</span>
          <b-form-radio
            class="mr-2"
            v-model="currentData.format"
            name="some-radios3"
            value="wav"
          >
            WAV
          </b-form-radio>
          <b-form-radio
            class="mr-2"
            v-model="currentData.format"
            name="some-radios3"
            value="ogg"
          >
            OGG
          </b-form-radio>
          <b-form-radio
            class="mr-2"
            v-model="currentData.format"
            name="some-radios3"
            value="mp3"
          >
            MP3
          </b-form-radio>
        </b-row>
      </b-col>
      <!-- Başlat Butonu -->
      <b-button
        class="mr-1"
        @click="VoiceExport"
        variant="success"
        style="float: right"
      >
        {{ lang("t_start") }}
      </b-button>
    </b-card>
  </div>
</template>



<script>
import {
  BBadge,
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BFormRadio,
  BButton,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import flatPickr from "vue-flatpickr-component";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
    BBadge,
    BCard,
    BCardText,
    BLink,
    BCardActions,
    flatPickr,
    BRow,
    BCol,
    BFormGroup,
    BCardCode,
    vSelect,
    BFormCheckbox,
    BFormInput,
    BFormRadio,
    BButton,
  },
  data: () => ({
    lang: _l(window.localStorage.getItem("language") || "tr"),
    filter_values: {
      startDate: new Date(
        new Date(new Date().setHours(0, 0, 0, 0)).getTime() + 3 * 60 * 60 * 1000
      )
        .toISOString()
        .replace(/([^T]+)T([^\.]+).*/g, "$1 $2"),
      endDate: new Date(
        new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
      )
        .toISOString()
        .replace(/([^T]+)T([^\.]+).*/g, "$1 $2"),
      selectedAgents: [],
      selectedQueues: [],
      selectedProjects: [],
      is_outbound: true,
      is_dialer: true,
      is_inbound: true,
    },
    currentData: {
      folder_format: "{{Day}}{{Month}}{{Year}}/{{Day}}",
      name_format: "",
      format: "mp3",
      emails: "",
    },
    list2: [],
    list3: [],
    agents: [],
    projects: [],
    queues: [],

    columns: [
      {
        internal_name: "StartTime",
        display_name: globalThis._lang("t_rStartTime"),
      },
      {
        internal_name: "BaseRemoteNumber",
        display_name: globalThis._lang("t_rBaseRemoteNumber"),
      },
      { internal_name: "Agent", display_name: globalThis._lang("t_rAgent") },
      {
        internal_name: "Direction",
        display_name: globalThis._lang("t_direction"),
      },
      {
        internal_name: "Verdict",
        display_name: globalThis._lang("t_rVerdict"),
      },
      {
        internal_name: "QueueName",
        display_name: globalThis._lang("t_queueName"),
      },
      {
        internal_name: "InteractionID",
        display_name: globalThis._lang("t_rInteractionID"),
      },
      {
        internal_name: "Müşteri No",
        display_name: globalThis._lang("t_customerId"),
      },
      {
        internal_name: "Adı Soyadı",
        display_name: globalThis._lang("t_rAdıSoyadı"),
      },
    ],
    folder_format_columns: [
      { internal_name: "Year", display_name: globalThis._lang("t_year") },
      { internal_name: "Month", display_name: globalThis._lang("t_month") },
      { internal_name: "Day", display_name: globalThis._lang("t_day") },
      { internal_name: "Agent", display_name: globalThis._lang("t_agent") },
    ],
  }),
  methods: {
    changeItemId(e) {
      console.log("changeItemId:", e); // e.to.__vue__.$attrs.group.type
      // e.item._underlying_vm_.parent = e.to.__vue__.$attrs.group.type;
    },
    changeItemId2(e) {
      console.log("changeItemId:", e); // e.to.__vue__.$attrs.group.type
      // e.item._underlying_vm_.parent = e.to.__vue__.$attrs.group.type;
    },
    cloneItem(item) {
      console.log(item);
      if (this.currentData.name_format.length > 0) {
        this.currentData.name_format += `_{{${item.internal_name}}}`;
      } else {
        this.currentData.name_format += `{{${item.internal_name}}}`;
      }
    },
    cloneItem2(item) {
      console.log(item);
      if (this.currentData.folder_format.length > 0) {
        this.currentData.folder_format += `/{{${item.internal_name}}}`;
      } else {
        this.currentData.folder_format += `{{${item.internal_name}}}`;
      }
    },
    GetUsers: async function () {
      var response = (await this.$http_in.get(`v_export/v1/User`)).data;

      this.agents = response;
    },
    GetProjects: async function () {
      var response = (await this.$http_in.get(`v_export/v1/Project`)).data;

      this.projects = response;
    },
    GetQueues: async function () {
      var response = (await this.$http_in.get(`v_export/v1/Queue`)).data;

      this.queues = response;
    },
    VoiceExport: async function () {
      this.$http_in.post(`v_export/v1/VoiceExport`, {
        filters: this.filter_values,
        info: this.currentData,
      });
      this.$swal({
        title: globalThis._lang("t_processSuccess"),
        text: "Export işlemi başlatıldı, işlem tamamlandığında ses dosyaları mail üzerinden iletilecektir.",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
  },
  mounted() {
    this.GetUsers();
    this.GetProjects();
    this.GetQueues();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
