<template>
  <div>
    <!-- Filters -->
    <!--
    <list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :extens-options="extensOptions"
      :project-options="projectOptions"
      :profile-options="profileOptions"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
      :isEdit="isEdit"
      :currentData="currentData"
    />

    <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ lang("t_show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ lang("t_entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="lang('t_search')"
              />
              <b-button
                variant="primary"
                @click="
                  modal_record_details = true;
                  isEdit = false;
                "
              >
                <span class="text-nowrap">{{ lang("t_addRecord") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        style="min-height: 300px"
        ref="refUserListTable"
        class="position-relative"
        :items="records"
        responsive
        fixed
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="lang('t_noRecord')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.role
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="updateSelected(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmText(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ lang("t_showing") }} {{ dataMeta.of }}
              {{ lang("t_entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_records"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Operator -->
          <validation-provider
            #default="validationContext"
            name="Operator"
            rules="required"
          >
            <b-form-group
              :label="lang('t_type')"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="currentData.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="['Callexper', 'Google', 'Nac']"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Display Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              :label="lang('t_displayName')"
              label-for="display_name"
            >
              <b-form-input
                id="display_name"
                v-model="currentData.display_name"
                :state="getValidationState(validationContext)"
                trim
              />
              <!--
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback> -->
            </b-form-group>
          </validation-provider>

          <!-- User Name -->
          <validation-provider #default="validationContext" rules="required">
            <b-form-group :label="lang('t_url')" label-for="url">
              <b-form-input
                id="user_name"
                v-model="currentData.url"
                :state="getValidationState(validationContext)"
                trim
              />
              <!--
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback> -->
            </b-form-group>
          </validation-provider>

          <!-- token -->
          <validation-provider #default="validationContext" rules="required">
            <b-form-group :label="lang('t_token')" label-for="token">
              <b-form-input
                id="token"
                v-model="currentData.token"
                :state="getValidationState(validationContext)"
                trim
              />
              <!--
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback> -->
            </b-form-group>
          </validation-provider>

          <!-- title -->
          <validation-provider
            #default="validationContext"
            name="port"
            rules="required"
          >
            <b-form-group :label="lang('t_port')" label-for="port">
              <b-form-input
                id="title"
                v-model="currentData.port"
                :state="getValidationState(validationContext)"
                trim
                type="number"
              />
              <!--
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback> -->
            </b-form-group>
          </validation-provider>

          <!-- isHttps -->

          <b-form-checkbox
            class="mt-1"
            v-model="currentData.isHttps"
            switch
            inline
          >
            {{ lang("t_isHttps") }}
          </b-form-checkbox>

          <!-- Form Actions
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{lang('t_add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{lang('t_cancel')}}
            </b-button>
          </div>
         -->
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useList from './useList'
import storeModule from '../storeModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // UsersListFilters,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      isEdit: false,
      currentData: {
        type: 'Callexper',
        display_name: '',
        url: '',
        token: '',
        port: 8090,
        isHttps: false,
      },
      columns: [],
      records: [],
      projects: [],
      modal_record_details: false,
      required,
      alphaNum,
      email,
      password,
      tableColumns: [
        { key: 'type', label: globalThis._lang('t_type'), sortable: true },
        { key: 'display_name', label: globalThis._lang('t_displayName'), sortable: true },
        { key: 'url', label: globalThis._lang('t_url'), sortable: true },
        { key: 'port', label: globalThis._lang('t_port'), sortable: true },
        { key: 'actions', label: globalThis._lang('t_action'), sortable: false },
      ]
    }
  },
  mounted: function () {
    this.get_records();
  },
  methods: {
    get_records: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/TTSProvider`)
      ).data;
      console.log("TTSProvider", response);
      this.records = response;
    },

    check_data() {
      if (this.currentData.display_name == '' || this.currentData.url == '') {
        return true;
      }
      return false;
    },

    add_records: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang('t_warning'),
          text: globalThis._lang('t_pleaseDoNotLeaveAnyBlankFields'),
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return;
      }

      var response;
      if (!this.isEdit) {
        console.log("this.currentData", this.currentData);
        response = (
          await this.$http_in.post(
            `spv/v1/TTSProvider`,
            { data: JSON.stringify(this.currentData) }

          )
        ).data;
      } else {
        response = (
          await this.$http_in.put(
            `spv/v1/TTSProvider`,
            {
              data: JSON.stringify(this.currentData),
            }
          )
        ).data;
      }

      // if (response != null || response != undefined) {
      //   if (response.command == 'UPDATE') {
      //     this.alert_message = `${this.black_list_number.number} ${globalThis._lang("t_hasBeenUpdatedSuccessfully")}`;
      //     this.alert_type = 'success';
      //     this.is_alert = true;
      //   } else {
      //     this.alert_message = globalThis._lang("t_hasBeenCreatedSuccessfully");
      //     this.alert_type = 'success';
      //     this.is_alert = true;
      //   }
      // }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      var response = (
        await this.$http_in.delete(`spv/v1/TTSProvider/${this.currentData._id}`)
      ).data;


      if (response != null || response != undefined) {
        this.$swal({
          icon: 'success',
          title: this.lang('t_processSuccess'),
          text: this.lang('t_customersHaveBeenSuccessfullyDeleted'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        type: 'Callexper',
        display_name: '',
        url: '',
        token: '',
        port: 8090,
        isHttps: false,
      }
    },
    updateSelected(item) {
      this.currentData = item;
      console.log("item", item);
      this.isEdit = true;
      this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang('t_deleteRecord'),
        text: this.lang('t_areYouSureYouWantToDeleteTheSelectedRecord'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.lang('t_yes'),
        cancelButtonText: this.lang('t_cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',

        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.delete_record();
        }
      })
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      contact: '',
    }

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    const isAddNewUserSidebarActive = ref(false)

    const operators = [
      { label: 'Nac Telekom', value: 'nac_telekom' },
      { label: 'Hermes İletişim', value: 'hermes_iletişim' },
    ]

    const extensOptions = [
      { label: '123', value: '123' },
      { label: '456', value: '456' },
      { label: '9999', value: '9999' },
      { label: '654', value: '654' },
    ]
    const projectOptions = [
      { label: 'Abc', value: '1' },
      { label: 'Proje2', value: '2' },
      { label: 'Proje3', value: '3' },
      { label: 'Proje4', value: '4' },
    ]
    const profileOptions = [
      { label: 'Admin', value: '1' },
      { label: 'Profil2', value: '2' },
      { label: 'Profil3', value: '3' },
      { label: 'Profil4', value: '4' },
    ]


    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      operators,
      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
