<template>
  <div>
    <b-row class="mb-1 mx-0" style="overflow-x: auto; white-space: nowrap">
      <b-button
        class="ml-1"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="
          selectedButton != 'voice-export'
            ? 'outline-primary'
            : 'relief-primary'
        "
        pill
        @click="selectedButton = 'voice-export'"
      >
        {{ lang("t_recordingExport") }}
      </b-button>
      <b-button
        class="ml-50"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="
          selectedButton != 'voice-remove'
            ? 'outline-primary'
            : 'relief-primary'
        "
        pill
        @click="selectedButton = 'voice-remove'"
      >
        {{ lang("t_recordingRemove") }}
      </b-button>
    </b-row>
    <b-card>
      <voice-export v-if="selectedButton == 'voice-export'"></voice-export>
      <voice-remove v-if="selectedButton == 'voice-remove'"></voice-remove>
    </b-card>
  </div>
</template>

<script>
import { BFormRadioGroup, BFormGroup, BRow, BCol, BCard, BCardText, BLink, BButton } from 'bootstrap-vue'

import VoiceExport from './VoiceExport/VoiceExport.vue'
import VoiceRemove from './VoiceRemove/RemoveVoice.vue'

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      selectedButton: 'voice-export',
    }
  },
  components: {
    VoiceExport,
    VoiceRemove,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton
  },
}
</script>

<style>
</style>
