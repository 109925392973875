<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ lang("t_show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ lang("t_entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="lang('t_search')"
              />
              <b-button
                variant="primary"
                @click="
                  modal_record_details = true;
                  isEdit = false;
                "
              >
                <span class="text-nowrap">{{ lang("t_addRecord") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        style="min-height: 300px"
        ref="refUserListTable"
        class="position-relative"
        :items="records"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="lang('t_noRecord')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(queue)="data">
          {{
            queues.find((e) => e.internal_name == data.item.queue).display_name
          }}
        </template>
        <template #cell(status)="data">
          <feather-icon
            size="18"
            class="text-success"
            v-if="data.value"
            icon="CheckCircleIcon"
          ></feather-icon>
          <feather-icon
            size="18"
            class="text-danger"
            v-else
            icon="XCircleIcon"
          ></feather-icon>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="
                readQrCode(data.item.internal_name, data.item.remote_number)
              "
            >
              <feather-icon icon="SmartphoneIcon" />
              <span class="align-middle ml-50">{{ lang("t_readQR") }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="updateSelected(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmText(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ lang("t_showing") }} {{ dataMeta.of }}
              {{ lang("t_entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_records"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Display Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              :label="lang('t_displayName')"
              label-for="display_name"
            >
              <b-form-input
                id="display_name"
                v-model="currentData.display_name"
                :state="getValidationState(validationContext)"
                trim
              />
            </b-form-group>
          </validation-provider>

          <!-- Remote Number -->
          <validation-provider
            #default="validationContext"
            name="remote_number"
            rules="required"
          >
            <b-form-group
              :label="lang('t_remoteNumber')"
              label-for="remote_number"
            >
              <b-form-input
                id="remote_number"
                v-model="currentData.remote_number"
                :state="getValidationState(validationContext)"
                trim
              />
            </b-form-group>
          </validation-provider>

          <!-- Queue -->
          <validation-provider
            #default="validationContext"
            name="queue"
            rules="required"
          >
            <b-form-group :label="lang('t_queue')" label-for="queue">
              <v-select
                :state="getValidationState(validationContext)"
                v-model="currentData.queue"
                :options="queues"
                :placeholder="lang('t_queue')"
                :reduce="(option) => option.internal_name"
                label="display_name"
              >
              </v-select>
            </b-form-group>
          </validation-provider>

          <!-- Prefix -->
          <validation-provider
            #default="validationContext"
            name="prefix"
            rules="required"
          >
            <b-form-group :label="lang('t_prefix')" label-for="prefix">
              <b-form-input
                id="prefix"
                v-model="currentData.prefix"
                :state="getValidationState(validationContext)"
                trim
              />
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider #default="validationContext" name="description">
            <b-form-group
              :label="lang('t_description')"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="currentData.description"
                :state="getValidationState(validationContext)"
                trim
              />
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      centered
      ok-only
      :ok-title="lang('t_ok')"
      :title="modal_qr_title"
      v-model="modal_qr"
      hide-footer
      @hide="get_records"
      @close="get_records"
    >
      <b-row align-h="center">
        <b-col class="text-center">
          <b-img :src="selected_qr"></b-img>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BFormTextarea, BImg
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useList from './useList'
import storeModule from '../storeModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // UsersListFilters,
    BFormTextarea,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    vSelect,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      isEdit: false,
      modal_qr: false,
      modal_qr_title: '',
      currentData: {
        display_name: '',
        remote_number: '',
        queue: '',
        prefix: '',
        description: '',
      },
      selected_qr: '',
      columns: [],
      records: [],
      queues: [],
      modal_record_details: false,
      required,
      alphaNum,
      email,
      password,
      tableColumns: [
        { key: 'status', label: globalThis._lang('t_status'), sortable: true },
        { key: 'display_name', label: globalThis._lang('t_displayName'), sortable: true },
        { key: 'remote_number', label: globalThis._lang('t_remoteNumber'), sortable: true },
        { key: 'queue', label: globalThis._lang('t_queue'), sortable: true },
        { key: 'actions', label: globalThis._lang('t_action'), sortable: false },
      ]
    }
  },
  mounted: function () {
    this.get_records();
    this.GetQueues();
  },
  methods: {
    async readQrCode(internal_name, remote_number) {
      this.selected_qr = '';
      var response = (
        await this.$http_in.get(`text-channel-wp/v1/QRCodeRaw/${internal_name}`)
      ).data;

      this.modal_qr_title = remote_number;
      this.selected_qr = response;
      this.modal_qr = true;
    },
    get_records: async function () {
      var response = (
        await this.$http_in.get(`text-channel-wp/v1/WhatsappProvider`)
      ).data;
      console.log("WhatsappProvider", response);
      this.records = response;
    },

    check_data() {
      if (this.currentData.display_name == '' || this.currentData.remote_number == '' || this.currentData.queue == '') {
        return true;
      }
      return false;
    },

    add_records: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang('t_warning'),
          text: globalThis._lang('t_pleaseDoNotLeaveAnyBlankFields'),
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return;
      }

      var response;
      if (!this.isEdit) {
        console.log("this.currentData", this.currentData);
        response = (
          await this.$http_in.post(
            `text-channel-wp/v1/WhatsappProvider`,
            { data: JSON.stringify(this.currentData) }

          )
        ).data;
      } else {
        response = (
          await this.$http_in.put(
            `text-channel-wp/v1/WhatsappProvider`,
            {
              data: JSON.stringify(this.currentData),
            }
          )
        ).data;
      }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      var response = (
        await this.$http_in.delete(`text-channel-wp/v1/WhatsappProvider/${this.currentData.internal_name}`)
      ).data;


      if (response != null || response != undefined) {
        this.$swal({
          icon: 'success',
          title: this.lang('t_processSuccess'),
          text: this.lang('t_customersHaveBeenSuccessfullyDeleted'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        display_name: '',
        remote_number: '',
        queue: '',
        prefix: '',
        description: '',
      }
    },
    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      let p_queues = globalThis.permissions["queue"];
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
        }
      }
    },
    updateSelected(item) {
      this.currentData = item;
      console.log("item", item);
      this.isEdit = true;
      this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang('t_deleteRecord'),
        text: this.lang('t_areYouSureYouWantToDeleteTheSelectedRecord'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.lang('t_yes'),
        cancelButtonText: this.lang('t_cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',

        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.delete_record();
        }
      })
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      contact: '',
    }

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    const isAddNewUserSidebarActive = ref(false)

    const operators = [
      { label: 'Nac Telekom', value: 'nac_telekom' },
      { label: 'Hermes İletişim', value: 'hermes_iletişim' },
    ]

    const extensOptions = [
      { label: '123', value: '123' },
      { label: '456', value: '456' },
      { label: '9999', value: '9999' },
      { label: '654', value: '654' },
    ]
    const projectOptions = [
      { label: 'Abc', value: '1' },
      { label: 'Proje2', value: '2' },
      { label: 'Proje3', value: '3' },
      { label: 'Proje4', value: '4' },
    ]
    const profileOptions = [
      { label: 'Admin', value: '1' },
      { label: 'Profil2', value: '2' },
      { label: 'Profil3', value: '3' },
      { label: 'Profil4', value: '4' },
    ]


    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      operators,
      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
