<template>
  <div>
    <b-row class="mb-1 mx-0" style="overflow-x: auto; white-space: nowrap">
      <b-button
        class="ml-50"
        style="width: 130px"
        :variant="selectedButton != 'wp' ? 'outline-primary' : 'relief-primary'"
        pill
        @click="selectedButton = 'wp'"
      >
        {{ lang("t_whatsapp") }}
      </b-button>
      <b-button
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'tts' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'tts'"
      >
        {{ lang("t_tts") }}
      </b-button>
      <b-button
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'stt' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'stt'"
      >
        {{ lang("t_stt") }}
      </b-button>
    </b-row>
    <b-card>
      <tts v-if="selectedButton == 'tts'"></tts>
      <stt v-if="selectedButton == 'stt'"></stt>
      <wp v-if="selectedButton == 'wp'"></wp>
    </b-card>
  </div>
</template>

<script>
import { BFormRadioGroup, BFormGroup, BRow, BCol, BCard, BCardText, BLink, BButton } from 'bootstrap-vue'

import tts from './TTS/list/List.vue'
import stt from './STT/list/List.vue'
import wp from './Whatsapp/list/List.vue'

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      selectedButton: 'wp',
    }
  },
  components: {
    stt,
    tts,
    wp,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton
  },
}
</script>

<style>
</style>
